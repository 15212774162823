import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Form, Button, Card } from "react-bootstrap";
import '../componentCss/webform.css';
import '../componentCss/sidebar.css';
import 'react-toastify/dist/ReactToastify.css';
import { getDashboardStatisticsAction, getFormDataListAction, getUpcomingEventAction, getPatientRecordDashboardAction } from '../../Action/admin.action';
import { Link, useNavigate } from 'react-router-dom';
import config from '../../config/config';
import { decryptData } from './decrypt';
import propertise, { reportsFileds } from '../../config/properties';
import DataTable from 'react-data-table-component';
import toast from 'react-hot-toast';

const StaffDashboard = () => {
    const navigate = useNavigate()
    const [statistics, setStatistics] = useState([]);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [getPatientRecordDashboard, setGetPatientRecordDashboard] = useState('');
    const [currentPage, setCurrentPage] = useState(0);
    const [upcomingEvents, setUpcomingEvents] = useState([]);
    const [userRole, setUserRole] = useState(0);
    const [daysSinceLastUpdate, setDaysSinceLastUpdate] = useState(0);
    const [formFields, setFormFields] = useState([]);
    const [formList, setFormData] = useState([]);
    const [totalRows, setTotalRows] = useState(0);
    const [filteredData, setFilteredData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [filterStatus, setFilterStatus] = useState(false);
    const [searchTerms, setSearchTerms] = useState({
        FirstName: '',
        LastName: '',
        MrnNumber: '',
        MobileNumber: '',
        EmiratesIdNumber: '',
        PassportNumber: '',
        dropdownValue: ''
    });

    useEffect(() => {
        getFormDataListAPI();
        getDashboardStatisticsAPI();
        getPatientRecordDashboardAPI();

    }, []);

    const handlePageChange = page => {
        setCurrentPage(page - 1);
    };

    const handleRowsPerPageChange = (newRowsPerPage) => {
        setRowsPerPage(newRowsPerPage)
    };

    const getPatientRecordDashboardAPI = async (fromDate, toDate) => {
        let res = await getPatientRecordDashboardAction({
            fromDate: fromDate, toDate: toDate
        });
        if (res.success) {
            let data = decryptData(res.data);
            setGetPatientRecordDashboard(data);
        }
    };

    const [formData1, setFormData1] = useState({
        from: '',
        to: '',
    });

    const handleChange1 = (e) => {
        const { name, value } = e.target;
        // Update formData state
        setFormData1((prevData) => ({
            ...prevData,
            [name]: value,
        }));

        // Use the updated value directly for actions
        const updatedData = {
            ...formData1,
            [name]: value,
        };

        // Perform action immediately with the updated data
        console.log('From Date:', updatedData.from);
        console.log('To Date:', updatedData.to);
        getPatientRecordDashboardAPI(updatedData.from, updatedData.to)

        // Add your action here (e.g., fetch data, update UI based on selected dates/years)
    };

    let getFormDataListAPI = async () => {
        let res = await getFormDataListAction({ id: propertise.patientAdmit });

        setIsLoading(false);
        if (res.success) {
            // Remove the element at index 2
            let decryptedData = decryptData(res.data?.fields);
            if (Array.isArray(decryptedData) && decryptedData.length > 2) {
                decryptedData.splice(2, 1);  // Remove the 3rd item (index 2)
            }

            setFormFields(decryptedData);
            setFormData(decryptData(res.data?.records));
            setTotalRows(res.data.totalRows);
            setFilteredData(decryptData(res.data?.records));
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setSearchTerms({
            ...searchTerms,
            [name]: value,
        });
    };

    const handleFilter = () => {
        let tempFormList = [...formList]

        const filteredData = tempFormList.filter(entry => {
            return (!searchTerms.FirstName || entry[propertise.PatientFirstName]?.trim().toLowerCase().includes(searchTerms.FirstName.trim().toLowerCase())) &&
                (!searchTerms.LastName || entry[propertise.PatientLastName]?.trim().toLowerCase().includes(searchTerms.LastName.trim().toLowerCase())) &&
                (!searchTerms.MrnNumber || entry.mrnNumber?.trim().toLowerCase().includes(searchTerms.MrnNumber.trim().toLowerCase())) &&
                (!searchTerms.MobileNumber || entry[propertise.PatientMobileNumber]?.trim().toLowerCase().includes(searchTerms.MobileNumber.trim().toLowerCase())) &&
                (!searchTerms.EmiratesIdNumber || entry.emrNumber?.trim().toLowerCase().includes(searchTerms.EmiratesIdNumber.trim().toLowerCase())) &&
                (!searchTerms.PassportNumber || entry[propertise.PatientPasportNumber]?.trim().toLowerCase().includes(searchTerms.PassportNumber.trim().toLowerCase())) &&
                (!searchTerms.dropdownValue || String(entry.status)?.trim().toLowerCase().includes(String(searchTerms.dropdownValue).trim().toLowerCase()))

        });
        if (filteredData.length) {
            setFilterStatus(true)
        } else {
            toast.error('Data not found')
        }
        setFilteredData(filteredData);
    }

    const handleReset = () => {
        setFilterStatus(false)
        setSearchTerms({
            FirstName: '',
            LastName: '',
            MrnNumber: '',
            MobileNumber: '',
            EmiratesIdNumber: '',
            PassportNumber: '',
            dropdownValue: ''
        })
    }

    const getDashboardStatisticsAPI = async () => {
        let res = await getDashboardStatisticsAction();
        if (res.success) {
            let data = decryptData(res.data);
            setStatistics(data.statistics);
            setDaysSinceLastUpdate(data.daysSinceLastUpdate);
            setUserRole(data.userRole)
            if (parseInt(data.userRole) > 2) {
                getUpcomingEventAPI()
            }
        }
    };

    const getUpcomingEventAPI = async () => {
        let res = await getUpcomingEventAction();
        if (res.success) {
            setUpcomingEvents(decryptData(res.data));
        }
    };

    const columns = [
        {
            name: '#',
            cell: (row, index, column, id) => {
                return (index + 1) + (currentPage * rowsPerPage);
            },
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
        {
            name: "EMR Number",
            selector: (row) => `${row.emrNumber}`,
            sortable: true,
        },
        {
            name: "MRN Number",
            selector: (row) => `${row.mrnNumber}`,
            sortable: true,
        },
        {
            name: "Name",
            selector: (row) => `${row[16]} ${row[18]}`,
            sortable: true,
        },

        {
            name: "Mobile Number",
            selector: (row) => `${row[30]}`,
            sortable: true,
        },
        {
            name: "DOB",
            selector: (row) => `${row[20]}`,
            sortable: true,
        },
    ];

    const handleRowClick = (e) => {
        localStorage.setItem('recordId', e.recordId);
        navigate(`${config.baseUrl}viewRecord/${propertise.patientAdmit}/${e.recordId}/Patient/isPatiendSearch`)
    }
    return (
        <div id="content">
            <Container fluid className="p-4">
                <Row>
                    <Col md={6} sm={6} className="mb-3">
                        <h5 className="headtitle">Dashboard</h5>
                    </Col>

                    <Col md={3} sm={3} className="mb-3">
                        <Form.Group className="fieldbox h-auto px-2">
                            <Form.Label>From</Form.Label>
                            <Form.Control
                                type="date"
                                className="form-control"
                                name="from"
                                value={formData1.from}
                                onChange={handleChange1}
                            />
                        </Form.Group>
                    </Col>

                    <Col md={3} sm={3} className="mb-3">
                        <Form.Group className="fieldbox h-auto px-2">
                            <Form.Label>To</Form.Label>
                            <Form.Control
                                type="date"
                                className="form-control"
                                name="to"
                                min={formData1.from} // Ensure 'To' date is greater than or equal to 'From' date

                                value={formData1.to}
                                onChange={handleChange1}
                            />
                        </Form.Group>
                    </Col>

                </Row>

                <Row className="text-center mb-4">
                    <Col md={4} sm={6}>
                        <Card className="statistic-card">
                            <Card.Body>
                                <Card.Title>No. of Admission</Card.Title>
                                <Card.Text className="count">{getPatientRecordDashboard.totalPatient}</Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md={4} sm={6}>
                        <Card className="statistic-card">
                            <Card.Body>
                                <Card.Title>No. of Discharge</Card.Title>
                                <Card.Text className="count">{getPatientRecordDashboard.dischargePatient}</Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md={4} sm={6}>
                        <Card className="statistic-card">
                            <Card.Body>
                                <Card.Title>No. of Active Patient</Card.Title>
                                <Card.Text className="count">{getPatientRecordDashboard.activePatient}</Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>

                {parseInt(daysSinceLastUpdate) >= 83 && (
                    <Row className="mb-4">
                        <Col>
                            <Card className="password-update-message">
                                <Card.Body>
                                    <strong>Notice:</strong> It's been {daysSinceLastUpdate || 0} days since you last updated your password. For your security, we recommend updating your password.
                                    <Link to={`${config.baseUrl}changePassword`}>
                                        <Button className="btn-primary mt-2">Update Password</Button>
                                    </Link>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                )}
                <Row>
                    <Col className={userRole > 2 ? 'border p-3 m-2 col-md-8' : 'border p-3 m-2 col'} style={{ boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
                        <Row>
                            <Col>
                                <div className="innerhead mb-4">
                                    <h5 className="headtitle">Search Patient</h5>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            {['FirstName', 'LastName', 'MrnNumber', 'MobileNumber', 'EmiratesIdNumber', 'PassportNumber'].map((field, index) => (
                                <Col key={index} md={4} sm={6} className="mb-3">
                                    <Form.Group className="fieldbox h-auto px-2">
                                        <Form.Label>{field.replace(/([A-Z])/g, ' $1')}</Form.Label>
                                        <Form.Control
                                            type={field === 'mobileNumber' ? 'number' : 'text'}
                                            placeholder={`Search By ${field.replace(/([A-Z])/g, ' $1')}`}
                                            className="form-control"
                                            name={field}
                                            value={searchTerms[field]}
                                            onChange={handleChange}
                                        />
                                    </Form.Group>
                                </Col>
                            ))}
                            <Col md={12} sm={12} className="mb-3">
                                <Form.Group className="fieldbox h-auto px-2">
                                    <Form.Label>Status</Form.Label>
                                    <Form.Select
                                        className="form-select"
                                        value={searchTerms.dropdownValue}
                                        onChange={handleChange}
                                        name="dropdownValue"
                                        aria-label="Default select example"
                                    >
                                        <option value="" disabled>Select Status</option>
                                        <option value="1">Active</option>
                                        <option value="2">Discharge</option>
                                        <option value="3">Deceased</option>
                                        <option value="4">Pre-admit</option>
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="d-flex">
                                <div className="">
                                    <Button className="px-3 py-2 btn-sm btn-secondary-btn3 fs-6 mt-4" onClick={() => handleFilter()}>Search</Button>
                                </div>
                                &nbsp;
                                <div >
                                    <Button className="px-3 py-2 btn-sm btn-secondary-btn3 fs-6 mt-4" onClick={() => handleReset()}>Reset</Button>
                                </div>
                            </Col>

                        </Row>
                        <hr />
                        <Row>
                            <Col>
                                <div className='dashboardTbl'>
                                   
                                    <DataTable
                                        columns={columns}
                                        data={filteredData}
                                        pagination
                                        onRowClicked={handleRowClick}
                                        paginationPerPage={rowsPerPage}
                                        paginationRowsPerPageOptions={[10, 15, 25, 50, 100]}
                                        paginationComponentOptions={{
                                            rowsPerPageText: 'Row per page:',
                                            rangeSeparatorText: 'out of',
                                        }}
                                        onChangePage={handlePageChange}
                                        onChangeRowsPerPage={handleRowsPerPageChange}
                                    />
                                </div>
                            </Col>
                        </Row>
                    </Col>

                    {userRole > 2 &&
                        <Col className='border p-3 m-2 upcomingEvent' style={{ boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', overflowX: 'auto', height: '500px' }} >
                            <Row>
                                <div className="innerhead mb-2">
                                    <h5>Upcomming Events</h5>
                                </div>
                            </Row>
                            <Row>
                                {upcomingEvents.length > 0 ?
                                    upcomingEvents.map((event, index) => (
                                        <Card className='mb-1'>
                                            <Card.Body>
                                                <Card.Text>{event.description}</Card.Text>
                                                <Card.Text>Assigned By: {event.assignedBy}</Card.Text>
                                                <Card.Text>Patient MRN: {event.mrnNumber}</Card.Text>
                                                <Card.Text>Date: {event.date} {event.time}</Card.Text>
                                            </Card.Body>
                                        </Card>
                                    ))
                                    :
                                    <>
                                        <p className='text-center'><h6>No data found!!</h6></p>
                                    </>
                                }
                            </Row>
                        </Col>
                    }

                </Row>




            </Container>
        </div>
    );
};

export default StaffDashboard;
