import React, { useState, useEffect } from "react";
import { Container, Row, Col, Form } from "react-bootstrap";
import "../componentCss/webform.css";
import { Formik } from "formik";
import "../componentCss/sidebar.css";
import toast, { Toaster } from 'react-hot-toast';
import { Link, useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import Modal from "react-bootstrap/Modal";
import config from "../../config/config";
import { Button } from "react-bootstrap";
import { loginSchema } from "../../validations/loginSchema";
import {
  updateFcmTokenAction,
} from "../../Action/admin.action";
import { useDispatch } from "react-redux";
import { login, smsVerification } from "../../redux/slices/userSlices";
import { getFirebaseToken } from "../../firebase";


const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const [formData, setForm] = useState({
    email: "",
    password: "",
    userGroupId: 0,
    otp: ""
  });
  const [isLaoding, setisLaoding] = useState(0);
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [otp, setotp] = useState(0);

  const handleClose = () => {
    setShow(false);
    setisLaoding(0)
  };
 
  let handleSubmit = async (values) => {
    setisLaoding(1);
    
    let res = await dispatch(smsVerification(values));
    if (res && res.payload && res.payload.success) {
      setShow(true);
      setForm(values)
      setotp(res.payload.data);
    } else {
      setisLaoding(0);
      
    }
  };

  // Generat FCM token

  const token = async (id) =>{
    getFirebaseToken()
    .then((firebaseToken) => {
      let obj = {
        id:id,
        fcm_token: firebaseToken
      }
      updateFcmTokenAction(obj);
      
    })
    .catch((err) => console.error('An error occured while retrieving firebase token. ', err))
}

 
  let submitHandler = async (e) => {
    e.preventDefault();
    setisLaoding(1);
    let res = await dispatch(login(formData))
    if(res && res.payload && res.payload.success){
      token(res.payload.data.id)
      setisLaoding(0);
      if(res.payload.data.role > 2){
        navigate(`${config.baseUrl}staffDashboard`)
      }else{
        navigate(`${config.baseUrl}dashboard`)
      }
    }
    
  };

  const otpHandler = async (e) => {
    const { name, value } = e.target;
    setForm((old) => {
      return { ...old, [name]: value };
    });

  }
  return (
    <>
      <div className="wrapper">
        <div id="content">
          <Container fluid className="p-4">
            <Row className="loginForm">
              <Col lg={12} md={12} sm={12} className="">
                <div className="formDiv">
                  <Formik
                    initialValues={formData}
                    validationSchema={loginSchema}
                    onSubmit={(values) => {
                      handleSubmit(values);
                    }}
                  >
                    {({
                      errors,
                      handleSubmit,
                      handleBlur,
                      handleChange,
                      touched,
                      values,
                    }) => (
                      <Form onSubmit={(e) => handleSubmit(e)}>
                        <Row className="col-md-12">
                          <div className="col-md-4"></div>
                          <div className="col-md-4 text-center">
                            <img
                              src={`${config.baseUrl}assets/images/logo2.png`}
                            />
                          </div>
                        </Row>

                        <Row className="col-md-12 mt-4">
                          <div className="col-md-4"></div>
                          <div className="col-md-4">
                            <Form.Label>Email</Form.Label>
                            <input
                              className="form-control"
                              type="email"
                              name="email"
                              onChange={handleChange}
                              placeholder="Please enter email"
                              onBlur={handleBlur}
                              value={values.email}
                            />

                            {errors.email && touched.email && (
                              <div className="text-danger">{errors.email}</div>
                            )}
                          </div>
                        </Row>

                        <Row className="col-md-12 mt-3">
                          <div className="col-md-4"></div>
                          <div className="col-md-4">
                            <Form.Label>Password</Form.Label>
                            <input
                              className="form-control"
                              type="password"
                              name="password"
                              onChange={handleChange}
                              placeholder="Please enter password"
                              onBlur={handleBlur}
                              value={values.password}
                            />

                            {errors.password && touched.password && (
                              <div className="text-danger">
                                {errors.password}
                              </div>
                            )}
                          </div>
                        </Row>

                        <Row className="col-md-12 mt-3">
                          <div className="col-md-4"></div>

                          <div className="col-md-4 m-2">
                            <div className="m-0">
                              <Link to={`${config.baseUrl}ForgotPassword`}>Forgot Password</Link>

                            </div>
                            <div className="mt-3">
                              {isLaoding ? (
                                <>
                                  <button
                                    disabled
                                    className="btn-lg btn btn-secondary-btn3 form-control"
                                  >
                                    Loading{" "}
                                    <img
                                      height="20"
                                      width="20px"
                                      src="assets/images/loading.gif"
                                    />
                                  </button>
                                </>
                              ) : (
                                <button
                                  type="submit"
                                  className="btn-lg btn btn-secondary-btn3 form-control"
                                >
                                  Login
                                </button>
                              )}
                            </div>


                            {/* <button
                              style={{ float: "right" }}
                              onClick={handleClick}

                              className="btn-sm btn btn-primary"
                            >
                              forgot Password
                            </button> */}
                          </div>
                        </Row>
                      </Form>
                    )}
                  </Formik>
                </div>
              </Col>
            </Row>
          </Container>
          <Modal show={show} onHide={handleClose} backdrop="static">
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                Enter OTP
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form onSubmit={submitHandler}>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>OTP</Form.Label>
                  <Form.Control type="text" placeholder="Eg:75926" name="otp" autoFocus onChange={(e) => otpHandler(e)} />
                </Form.Group>
                <label>Your Otp is : {otp}</label>
              </Form>
            </Modal.Body>
            <Modal.Footer>

              <Button className="btn btn-secondary-btn3" type="submit" onClick={submitHandler}>Submit</Button>

            </Modal.Footer>

          </Modal>
        </div>
      </div>
    </>
  );
};

export default Login;
